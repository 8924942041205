<template>
  <div class="box">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            @click="dialogVisible = true"
            class="header_imgs"
            src="../../assets/peixun.png"
            alt=""
          />
        </div>
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="bj">
      <div class="w10">
        <div class="title">
          {{ detail.title || "暂无标题" }}
        </div>
        <div class="time">
          <div>{{ detail.addtime }}</div>
          <!-- <div>浏览量：{{ detail.views }}</div> -->
        </div>
        <div class="content">
          <div v-html="detail.content"></div>
        </div>

        <div style="height: 500px"></div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      detail: {},
      dialogVisible: false,
    };
  },
  methods: {
    ...mapActions(["getnewsDetail1"]),
  },
  mounted() {
    this.getnewsDetail1({
      id: this.$route.query.id,
    }).then((res) => {
      if (res.code == 200) {
        //测试地址需要加网址前缀
        // res.data.content = res.data.content.replace(
        //   /<img src=\"/gi,
        //   '<img style="height:auto; display: block;margin: 0 auto;" src="https://bes.zpton.com'
        // );
        this.detail = res.data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
}

.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}
.bj {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
}
.title {
  margin-top: 103px;
  margin-bottom: 26px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.time {
  display: flex;
  justify-content: space-between;
  padding: 0 44px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #a5a5a5;
}
.content {
  margin-top: 26px;
}
.img {
  text-align: center;
  margin: 74px 0;
}
</style>
